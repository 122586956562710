import { action } from '@ember/object';
import { testAwareWaitForLater } from '../../../utils/waiters';
import { tracked } from '@glimmer/tracking';
import Super from '../validatable-component';
import styles from './message.module.scss';

const CTA_ALIGNMENTS = ['column', 'row'];
const SUPPORTED_TYPES = ['error', 'info', 'success', 'warning'];

export default class SpdsAlertBannerMessage extends Super {
  arguments = [
    {
      type: 'action',
      optional: ['onClose'],
    },
    {
      type: 'boolean',
      optional: ['closeable', 'includeIcon'],
    },
    {
      type: 'configOption',
      optional: [
        {
          name: 'ctaAlignment',
          options: CTA_ALIGNMENTS,
        },
      ],
      required: [
        {
          name: 'type',
          options: SUPPORTED_TYPES,
        },
      ],
    },
    {
      type: 'string',
      optional: ['icon'],
    },
  ];
  componentName = 'Spds::AlertBanner::Message';
  styles = styles;

  @tracked open = true;

  get classes() {
    let { global, type } = this.args;
    let classes = [this.styles.component, 'spds-alert-banner-message-container', type];

    if (global) classes.push('global');

    if (this.open) classes.push('open');

    return classes.join(' ');
  }

  get icon() {
    let { icon, includeIcon, type } = this.args;

    if (icon) return icon;

    if (includeIcon) {
      switch (type) {
        case 'error':
          return 'triangle-exclamation-mark';
        case 'success':
          return 'circle-check';
        default:
          return 'circle-i';
      }
    }

    return null;
  }

  get showCloseBtn() {
    let { closeable, global, onClose } = this.args;

    return (closeable && global) || onClose;
  }

  @action
  async close() {
    this.open = false;

    await testAwareWaitForLater(300);

    this.args.onClose?.();
  }
}
