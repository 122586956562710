import { action } from '@ember/object';
import { createElement, error } from 'client-portal/utils/banners';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class ContactWidgetController extends Controller {
  @service iframe;
  @service router;
  @service currentPractice;
  @service session;
  @tracked contentLoading = false;
  @tracked complete = false;
  @tracked clinicianId = null;
  @service analytics;

  get client() {
    return this.model.client;
  }

  get clinician() {
    return this.model.clinician;
  }

  get name() {
    return this.clinician ? this.clinician.get('fullName') : this.currentPractice.get('fullName');
  }

  @action
  close() {
    this.iframe.close();
  }

  @action
  async save() {
    try {
      this.#setRecaptchaResponse();

      if (!this.#validate()) return;

      await this.client.save({
        adapterOptions: {
          embed: ['emails', 'phones'],
        },
      });

      this.analytics.trackAnonymously('Contact Form Send Message Clicked', {
        object: 'Contact Form Send Message',
        action: 'Clicked',
        'site_name': this.session.channel,
      });

      this.complete = true;
      this.iframe.startHeightBroadcast();
    } catch (err) {
      this.#showErrorMessage(err);
    }
  }

  #validate() {
    this.client.validate();
    this.client.set('_isDirty', true);
    return (
      this.client.validations.isValid &&
      this.client.emails.firstObject.validations.isValid &&
      this.client.phones.firstObject.validations.isValid
    );
  }

  #setRecaptchaResponse() {
    this.client.set('recaptchaResponse', window.grecaptcha.getResponse());
  }

  #resetRecaptcha() {
    window.grecaptcha.reset();
  }

  #showErrorMessage(err) {
    let { errors } = err;
    let content = createElement('ul');
    let title = 'Unable to save';

    errors.forEach(({ title, meta, detail }) => {
      let innerHTML = title;
      if (title.indexOf('reCAPTCHA verification failed') >= 0) {
        this.#resetRecaptcha();
      } else if (title.indexOf('Email is already in use') >= 0) {
        innerHTML = this.#emailError();
      } else if (
        title.indexOf('Internal Server Error') >= 0 ||
        title.indexOf('Feature not enabled') >= 0
      ) {
        // Do nothing
      } else {
        innerHTML = `${meta.type} ${meta.attribute} ${detail}`;
      }
      content.appendChild(createElement('li', { innerHTML }));
    });

    error({ title, content });
  }

  #emailError() {
    let content = 'Email is already in use. If this is your email,';

    let phoneNumber = this.currentPractice.get('defaultPhone.number');

    if (phoneNumber) {
      content = `${content} contact us at ${phoneNumber}, or`;
    }

    content = `${content} <a href="${this.router.urlFor(
      'sign-in'
    )}" target="_blank" rel="noopener">sign in to the Client Portal</a> to request a new appointment.`;
    return content;
  }
}
