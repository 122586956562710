import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ContactWidgetRoute extends Route {
  @service analytics;
  @service store;
  @service currentPractice;
  @service router;
  @service session;

  queryParams = {
    clinicianId: { refreshModel: true },
    appearance: { refreshModel: true },
  };

  beforeModel() {
    super.beforeModel(...arguments);

    if (
      !this.currentPractice.get('featurePracticeContactForm') ||
      (!this.currentPractice.get('practiceContactForm') &&
        !this.currentPractice.get('monarchContactForm'))
    ) {
      this.router.transitionTo('home');
    }
  }

  async model({ clinicianId }, _transition) {
    let clinician = clinicianId ? await this.store.findRecord('clinician', clinicianId) : null;
    let client = this.store.createRecord('prospective-client', {
      emails: [this.store.createRecord('email', { prospectiveClient: true })],
      phones: [this.store.createRecord('phone', { prospectiveClient: true })],
      globalMonarchChannelName: this.session.channel,
      clinician,
    });
    return { client, clinician };
  }

  setupController(controller) {
    super.setupController(...arguments);

    let appearance = controller.appearance;
    let fullScreen = false;
    if (appearance) {
      let hash = { fullScreen: false };
      try {
        hash = JSON.parse(appearance);
      } catch (err) {
        // noop
      }

      fullScreen = hash.fullScreen;
    }
    controller.set('isFullscreen', fullScreen);
  }

  afterModel(_resolvedModel, _transition) {
    super.afterModel(_resolvedModel, _transition);

    this.analytics.trackAnonymously('Contact Form Widget Opened', {
      Object: 'Contact Form Widget',
      Action: 'Opened',
      'site_name': this.session.channel,
    });
  }
}
